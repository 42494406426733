import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./JoseParla.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import JoseParlaPhoto1 from "../../../../res/Photos site/Jose Parla/jose-parla.jpeg"

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "JoseParla",
  name: "JoseParla",
  description:"Born in 1973 en Floride de parents cubains en exil, José Parla est un artiste américain connu pour ses toiles expressionnistes issues de recherches calligraphiques. Passé par la New World School of the Arts de Miami dans les années 1990, José Parla explore la peinture calligraphique et les graffitis sur les murs des villes afin d’exprimer sa vision du monde urbain comme une histoire vivante. Le travail de Parla ayant acquis une reconnaissance internationale fait l’objet de nombreuses expositions personnelles de musées dont récemment : The Bronx Museum of the Arts, New York, Gana Art, Entropies, Seoul, South Korea (2020), HOCA Foundation, Hong Kong (2019), Neuberger Museum of Art, Purchase, New York (2018) et fait partie de collections telles que : The National Museum of Fine Arts, Havana, Cuba, The British Museum, London, U.K, The Burger Collection, Hong Kong, … Concerné par ses origines et à la tête de l’art cubain contemporain cubain, il travaille sur un projet de fresques murales à Cuba avec l’artiste français JR (2012). ",
  pdp: JoseParlaPhoto1,
  alt_pdp: "Photo de profil de JoseParla.",
  photos: [
    { src: JoseParlaPhoto1, name: "JoseParla" },
  ],
  works: ["Works", "Works", "Works"],
  videos: ["Videos", "Videos", "Videos"],
};

const JoseParla = () => {
    return (
        <div className={Wrapper}>
        <div className={CardWrapper}>
          <div className={ProfilWrapper} id="f">
            <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
          </div>
          <div>
            <h1 className={NameWrapper}>José Parla</h1>
            <h3 className={NameWrapper} style={{paddingTop: '0'}}>Born in 1973</h3>
            <p className={DescriptionWrapper}>{infos.description}</p>
          </div>
        </div>
        <div className={PhotosWrapper}>
          <Title title={"Past or Present"} />
          <div className={CarrouselWrapper2}>
          </div>
        </div>
      </div>
      );
};

export default JoseParla;