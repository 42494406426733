// extracted by mini-css-extract-plugin
export var ArtistDescription = "JoseParla-module--ArtistDescription--YE2Ro";
export var ArtistInfos = "JoseParla-module--ArtistInfos--dyxKc";
export var ButtonWrapper = "JoseParla-module--ButtonWrapper --CVIUd";
export var CardWrapper = "JoseParla-module--CardWrapper--7h0pP";
export var CarrouselWrapper2 = "JoseParla-module--CarrouselWrapper2--P4P29";
export var Citations = "JoseParla-module--Citations--J6khx";
export var DescriptionWrapper = "JoseParla-module--DescriptionWrapper--COjky";
export var ImageWrapper = "JoseParla-module--ImageWrapper--7OZTc";
export var LinkWrapper = "JoseParla-module--LinkWrapper--3qxjR";
export var MobileProtrait = "JoseParla-module--MobileProtrait---5mJ6";
export var More = "JoseParla-module--More--V3BW-";
export var MoreButton = "JoseParla-module--MoreButton--jKxtD";
export var NameWrapper = "JoseParla-module--NameWrapper--uv6r3";
export var PdpWrapper = "JoseParla-module--PdpWrapper--UnN5g";
export var PhotosWrapper = "JoseParla-module--PhotosWrapper--+zNP0";
export var ProfilWrapper = "JoseParla-module--ProfilWrapper--mi64m";
export var TitleWrapper = "JoseParla-module--TitleWrapper--9tGjR";
export var Wrapper = "JoseParla-module--Wrapper--PXbiX";